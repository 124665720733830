import {useNews} from '~/stores/news';
import {useApi} from '~/composables/api';

export default defineNuxtRouteMiddleware(async to => {
	const news = useNews();

	console.log('%c- middleware ENTITIES NEWS', 'color:#0a0', process.server ? 'server' : 'frontend');

	const api = useApi();
	// await news.fetchNewsEntities();

	if (!news.tags.length) {
		const tags = await api.news.fetchEntitiesTags();

		if (tags) {
			for (const tag of tags) {
				tag.type = 'tag';
			}

			news.tags = tags;
		}
	}

	if (!news.influencers.length) {
		const sources = await api.news.fetchEntitiesSources();

		if (sources) {
			sources.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

			news.influencers = sources;

			news.initChecked();
		}
	}

	// skip ignore on server
	// if (process.server) return
	// // skip ignore on client side entirely
	// if (process.client) return
	// // or only skip ignore on initial client load
	// const nuxtApp = useNuxtApp()
	// if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return
})
